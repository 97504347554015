<template>
<div class="patwords" :class="{loading: !init}">
	<div class="sectionMainWrap">
		<div class="sectionMain">
			<div class="ruler">
				<div v-for="(item, index) in data" :key="index" :style="{ width: space+'px' }" :class="{'mid': item.mid,'wt': item.wt, 'placeholder': item.type === 'placeholder'}" :data-id="item.id">
					<span class="rulerValue" v-if="item.wt" :style="{ marginLeft: space/2+'px' }">{{item.formatRight}}</span>
				</div>
			</div>
			<div class="subtitleList" :style="{ height: depth >=3 ? (depth*22 + 20) +'px': 'auto' }">
				<template v-for="(item, index) in subtitleDatas">
				<div class="subtitleItem" v-if="parseFloat(item.width) > 0" :key="index" :style="{width: item.width, left: item.left, zIndex: index+1,top:item.top+'px'}" :title="item.originalDialogueContent + '\nStart: ' + timeFormat(item.start, 1)+'\nEnd: '+ timeFormat(item.end, 1)">
					<span class="boundary"></span>
					<span class="boundary-mid" @click="subtitleClick(item)">{{ item.originalDialogueContent }}</span>
					<span class="boundary"></span>
				</div>
				</template>
			</div>
			<div class="playSolid" v-show="playSolid && progress" :style="{ transform: 'translateX('+progress+')', transition}"></div>
		</div>
	</div>
	<div class="loading-content" v-if="!init">等待播放器准备就绪.....</div>
</div>
</template>
<script>
const options = {
	init: false, // 组件是否可以开始初始化
	domReady: false, // 组件是否mounted且dom ready
	subtitleData: [], // 字幕数据用于刻度尺上面的展示，PS：这里的数据按照字幕start排序
	data: [], // 刻度尺标的数据
	currentTime: 0, // 当前视频播放的时间
	duration: 0,// 当前展示的刻度显示的总秒数，基本上是根据页面的宽度自动算出能展示多少秒
	rulerBegin: 0,// 当前刻度的起始时间点
	rulerEnd: 0,// 当前刻度的结束时间点
	rulerFluid: false, // 刻度的duration是否根据外层容器自适应
	caliperWidth: 2, // 游标指针的宽度, 单位像素
	playSolid: true, // 游标指针是否显示
	progress: 0, // 游标指针的位置,单位像素
	timeByPX: 0,// 一像素代表多少时长
	space: 8, // 一刻度占多少像素值
	step: 100, // 一刻度表示的毫秒数
	domWidth: 0,
	transition: 'all 0.5s linear',
	depth: 1	
}

export default {
	name: "Ruler",
    data(){
        return {
			...options, 
			...{
				sectionMain: null,
				videoPlayer: {},
				playerTimeupdate: '',
				resizeFn: null
			}
		}
	},
	props:{
		value: {
			required: true,
			type: Array
		},
		player:{
			required: true,
			type: Object
		},
		fluid:{
			type: Boolean,
			default: false
		},
		begin: {
			required: true,
			type: Number
		},
		end:{
			type: Number
		}
	},
	computed:{
		subtitleDatas(){
			const { rulerBegin, rulerEnd, step, space, getLeftValue }  = this
			const subtitleDataCopy = _.cloneDeep(this.subtitleData)
			let result = []
			let pre = null
			let depths = [1]
			let depth = 1

			result = subtitleDataCopy.map( value=> {
				const { end, start } = value
				if(!end && !start) {
					/**
					 * 针对直播节目翻译只有字幕但是没有任何字幕时间戳的情况
					 */
					return value
				}
				let left
				let width
				let item = {
					lapped: false,
					top: 10
				 }

				if(end <= rulerBegin || start >= rulerEnd){
					left = false
				}else{
					if(start >= rulerBegin){
						left = getLeftValue(start)
						width = ((( end <= rulerEnd ? end : rulerEnd ) - start) / step) * space
						if(end > rulerEnd) item.break = "end";
					}else{
						if(end > rulerBegin){
							left = getLeftValue(rulerBegin)
							width = ( ( end - rulerBegin ) / step ) * space
							item.break = "start"
						}else{
							left = false
						}
					}
				}

				if(left >= 0 && width){
					// 这边的width没有width > 0 来判断 是由于在拍词的情况下，一句字幕还没有拍完整时，拍的开始时间是允许被拍成大于结束时间的，这样width就变成负值了，但是拍词的patwordsEdit值是变化的，所以数据还是需要响应式的
					item.width = width + 'px'
					item.left = left + 'px'
				}else{
					delete value.width
					delete value.left
					delete value.break
				}

				/**
				 * 判断是否重叠
				 */
				if(pre && left >= 0 && left <= (parseFloat(pre.width) + parseFloat(pre.left))){
					pre.lapped = true
					item.lapped = true
					item.top = depth * 22 + 10
					depth = depth + 1
					depths.push(depth)
				}else{
					depth = 1
				}
				pre = item

				return {
					...value, 
					...item 
				}
			})

			this.depth = Math.max.apply([], depths)
			return result.filter(v=>{
				return v.left ? true : false
			})
		}
	},
	watch:{
		value: {
			immediate: true,
			handler(v) {
				this.subtitleData = _.cloneDeep(v).sort((v, v2)=>{
					return v.start - v2.start
				})
			},
			deep: true
		},
		begin: {
			immediate: true,
			handler(v) {
				if(v !== this.rulerBegin && this.init){
					this.rulerBegin = this.redressBeginValue(v)
					this.showRuler()
				}
			}
		},
		end:{
			immediate: true,
			handler(v) {
				if( v !== this.rulerEnd && this.init){
					this.rulerEnd = v
					this.showRuler()
				}
			}
		},
		fluid:{
			immediate: true,
			handler(v) {
				if(this.init){
					this.rulerFluid = v
					this.showRuler()
				}
			}
		},
		player: {
			immediate: true,
			handler(v) {
				// 播放器实例化完后在初始化拍词模块
				if(v.play && !this.init){
					this.videoPlayer = v
					this.mountedInit()
				}
			}
		}
	},
	mounted(){
		this.domReady = true
		if(!this.init && this.videoPlayer.play){
			this.mountedInit()
		}
	},
	destroyed(){
		if(this.init){
			this.init = false
			this.domReady = false
			this.videoPlayer.off('timeupdate', this.playerTimeupdate)
			window.removeEventListener("resize", this.resizeFn)
			this.videoPlayer = null
		}
	},
    methods:{
		mountedInit(){
			if(!this.domReady) return false
			this.sectionMain = this.$el.querySelector('.sectionMain')
			this.domWidth = this.sectionMain.offsetWidth;
			this.rulerBegin =  this.redressBeginValue(this.begin)
			this.rulerEnd = this.end
			this.rulerFluid = this.fluid
			this.showRuler()
			this.playerActions()
			// this.resize()
			this.init = true
		},

		/**
		 * 刻度尺初始值矫正，为什么要矫正：
		 * 假如 rulerBegin = 384152398，step=100；rulerBegin/step除不尽余数为98, 根据rulerRander获取刻度数据的逻辑可以看出虽然除不尽但是刻度的第一个值是从 Math.floor(rulerBegin/step) 取整数开始累加的，也就是说这98毫秒其实刻度尺已经把它抛弃了，但是刻度尺上面显示的刻度数据却没有抛弃，于是将近一个刻度的误差就这样产生了，之前一直误差较小是因为余数较小，误差可以忽略，如果想98这种那么误差就明显了, 如果step=200||300时，那么误差也很明显
		 */
		redressBeginValue(beginValue){
			return beginValue - beginValue % this.step
		},

        getObjPos:function(obj){
			let x =0;
			let y = 0;
            if (obj.getBoundingClientRect){
                var box = obj.getBoundingClientRect();
                if(box.left == 0 && box.top == 0)
                {
                    return;
                }
                var D = document.documentElement;
                x = box.left + Math.max(D.scrollLeft, document.body.scrollLeft) - D.clientLeft;
                y = box.top + Math.max(D.scrollTop, document.body.scrollTop) - D.clientTop;

            } else {
                for(; obj != document.body; x += obj.offsetLeft, y += obj.offsetTop, obj = obj.offsetParent );
            }
            return {x,y}
		},

		resize(){
			let resizeTime
			this.resizeFn = function(){
				resizeTime && clearTimeout(resizeTime)
				resizeTime = setTimeout(()=>{
					this.showRuler()
				}, 500)
			}.bind(this)
			window.addEventListener("resize", this.resizeFn)
		},

		subtitleClick(item){
			this.$emit("subtitleClick", item)
		},

		playerActions(){
			const { videoPlayer, transformByCurrentTime } = this
			this.playerTimeupdate = function(){
				const currentTime = videoPlayer.currentTime()
				this.transition = 'all 0.5s linear'
				this.currentTime = currentTime * 1000
				transformByCurrentTime(currentTime * 1000, 'timeupdate')
			}.bind(this)
			videoPlayer.on('timeupdate', this.playerTimeupdate)
		},

		/**
		 * @time 毫秒
		 */
		timeFormat(time, type){
			const hour = Math.floor(time / (1000*60*60))
			const minute = Math.floor((time % (1000*60*60)) / (1000*60))
			const second = Math.floor((time % (1000*60)) / 1000)
			const millisecond = "0000" + Math.floor(time % 1000)
			const twodigit = (num) => num < 10 ? '0' + num : num
			const template = `${twodigit(hour)}:${twodigit(minute)}:${twodigit(second)}`
			return !type ? template : template +':'+ millisecond.slice(millisecond.length - 3)
		},

		/**
		 * 根据 currentTime 获取相对刻度尺的left值
		 */
		getLeftValue(currentTime){
			const {rulerBegin, rulerEnd, step, space} = this
			if(currentTime >= rulerBegin && currentTime <= rulerEnd){
				return ((currentTime - rulerBegin) / step) * space
			}
			return false
		},

		/**
		 * 根据播放器当前的播放currentTime值转换对应的红色进度条的坐标和刻度的rulerBegin和rulerEnd值
		 */
		transformByCurrentTime(currentTime, type){
			const { rulerBegin, rulerEnd, duration, getLeftValue, caliperWidth } = this
			const leftValue = getLeftValue(currentTime) 
			this.playSolid = true
			this.progress = leftValue ? ((getLeftValue(currentTime) - caliperWidth) + 'px') : false
		},

		showRuler(){
			let { rulerBegin, rulerEnd, step, space, domWidth, fluid, duration } = this;
			const stepCardinal = 100 // step基数，值为100，单位毫秒

			if(fluid){ // 刻度 duration 根据外容器的宽度自适应
				duration = Math.floor((domWidth / space)) * step  //计算当前dom的宽度按照目前的setp和space能展示多少秒的刻度
				rulerEnd = rulerBegin + duration
			}else{
				duration = Math.ceil(rulerEnd - rulerBegin)
				if(duration <= 0) return false
				let physicalWidth = (domWidth/duration) * ( stepCardinal *10 )  // 10个刻度所占据的物理像素值
				let stepCoefficient = Math.round(100 / physicalWidth) // step系数，以10个刻度宽度为100px为基础进行计算
				step = stepCardinal * (stepCoefficient === 0 ? 1 : stepCoefficient) // step动态化，如果duration值越大那么一个step表示的毫秒数就越多，默认setp是100毫秒
				space = domWidth / Math.ceil(duration / step)
				// console.log({domWidth, duration, physicalWidth , stepCoefficient, space, step})
			}
			this.duration =  duration
			this.space = space
			this.step = step
			this.rulerEnd = rulerEnd
			this.rulerRander()
		},

		/**
		 * 刻度展示数据获取，return 一个数组
		 */
        rulerRander(){
			let { rulerBegin, rulerEnd , timeFormat, step } = this
			if(Number(rulerBegin) >=0 || Number(rulerEnd) > 0 ) {
				let rulers =[]
				let id = Math.floor(rulerBegin / step)
				if(id >=0 ){
					rulers.push({
						mid: id % 5 === 0 && id % 10!==0 ? true : false,
						wt: id % 10 === 0 ? true : false,
						formatRight: timeFormat(rulerBegin),
						id,
						type: "placeholder"
					})
				}
				while(rulerBegin < rulerEnd){
					rulerBegin = rulerBegin + step;
					id = id +1
					rulers.push({
						formatLeft: timeFormat(rulerBegin - step),
						formatRight: timeFormat(rulerBegin),
						value: rulerBegin,
						mid: id % 5 === 0 && id % 10!==0 ? true : false,
						wt: id % 10 === 0 ? true : false,
						id
					})
				}
				this.data = rulers
			}
		}
    }
}
</script>
<style lang="scss" scoped>
.loading{
		position: relative;
	> div{
		visibility: hidden;
	}
	.loading-content{
		position: absolute;
		top:0;
		right:0;
		left:0;
		bottom:0;
		display: flex;
		justify-content: center;
		align-items: center;
		visibility: visible;
	}
}
.patwords{
	font-size:12px;
	border: 1px solid #e8e8e8;
	border-radius: 5px 5px 0 0;
	box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
	margin:16px 7px 7px;
	.ruler{
		position: relative;
		width: 100%;
		display: flex;
		height: 30px;
		align-items: flex-end;
		border-bottom:1px solid #999;
		user-select: none;
		div{
			position: relative;
			flex: 0 0 auto;
			width:10px;
			height: 4px;
			border-right:2px solid #999;
		}
		.placeholder{
			bottom:0;
			left:-2px;
			width:2px!important;
			position: absolute;
			&:after{
				position: absolute;
				content: ".";
				width:2px;
				height: 1px;
				line-height: 0;
				left:0;
				bottom:-1px;
				text-indent: -999px;
				background: #999;
				overflow: hidden;
			}
		}
		.mid{
			height: 8px;
		}
		.wt{
			height: 12px;
		}
		.rulerValue{
			position: absolute;
			top:-18px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.subtitleList{
		position: relative;
		min-height: 60px;
	}
	.subtitleItem{	
		position: absolute;
		top:10px;
		width:190px;
		height: 20px;
		left: 0;
		line-height: 20px;
		background: rgba(24,115,200,.8);
		padding:0 4px;
		color: #fff;
		text-overflow: ellipsis;
		box-sizing: border-box;
		transition: top 0.5s linear;
		overflow: hidden;
		&:hover{
			background:#690
		}
		.boundary{
			position: absolute;
			top:0;
			left:0;
			bottom:0;
			width:4px;
			&:last-child{
				left:auto;
				right:0;
			}
		}
		.boundary-mid{
			display: block;
			height: 20px;
		}
	}
	.sectionMainWrap{
		padding: 0 27px;
	}
	.sectionMain{
		position: relative;
		padding-top:20px;
		.playSolid{
			position: absolute;
			left: 0;
			top: 10px;
			line-height: 0;
			bottom:0;
			width:2px;
			background: #f00;
			transform: translateX(0);
			z-index: 111;
			&:before{
				position: absolute;
				left: 50%;
				top: -10px;
				transform: translateX(-50%);
				width: 8px;
				height: 10px;
				content: ".";
				text-indent: -999px;
				background: #f00;
				overflow: hidden;
			}
			&:after{
				position: absolute;
				left:-3px;
				top: 0;
				width:0;
				height: 0;
				content: ".";
				text-indent: -999px;
				border-bottom:4px dashed transparent;
				border-right:4px dashed transparent;
				border-left:4px dashed transparent;
				border-top:4px solid #f00;
				overflow: hidden;
			}
			&:hover{
				cursor: pointer;
			}
		}
	}
}
</style>