<template>
	<ul class="line-book-title">
		<template v-for="(item , index) in bookInfos">
			<li class="line-book-title-item">
				<el-popover effect="light" :open-delay="500" :offset="-75" trigger="hover">
					<span class="line-book-title-item-content" slot="reference">
						{{index + 1}}.{{item.name}}
					</span>	
					<div class="line-book-content-item">
						<div class="line-book-content-item-copy">
							<el-button type="text" size="small" @click="copyBookContent(item)">一键导入</el-button>
						</div>
						<div class="line-book-content">
							<template v-for="word in item.originalLanguageContents">
								<div class="line-book-content-word">{{word.content}}</div>
							</template>
						</div>		
					</div>			
				</el-popover>
			</li>
		</template>
		
	</ul>
</template>

<script type="text/javascript">
	export default {
		name : "line-book-title",
		props : {
			taskId : {
				type : [Number , String],
				default : ""
			},
			titles : {
				type : Array,
				default : ()=>([])
			},
			name : {
				type : String,
				default : ""
			}
		},
		data(){
			return {
				bookInfos : []
			}
		},
		methods : {
			//获取任务下台本详情
			getDetail(){
				let url = `/api-intl-translate-live/translate-live-script`;
				$$.get(url , {
					params : {
						taskId : this.taskId
					}
				}).then(data => {
					console.log(data);
					this.bookInfos = data;
				}).catch(err => {
					this.$message.error(err.message);
				})
			},
			//复制当前台本内容
			copyBookContent(item){
				console.log(item.originalLanguageContents);
				this.$emit("copyClick" , item)
			}
		},
		mounted(){
			this.getDetail();
		}
	};
</script>

<style lang="scss">
		.line-book-title{
			margin-left : 5px;
			padding-left : 0px;
			.line-book-title-item{
				list-style: none;
				height : 50px;
				line-height : 50px;
				border: 1px solid;
			    margin: 25px 0;
			    border-radius: 15px 5px 5px 15px;
				.line-book-title-item-content{
					display : block;
					overflow : hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					padding-left : 15px;
					cursor: pointer;
					&:hover{
						color : red;
					}
				}
			}
		}
		.placement{
			margin-right : 50px;
		}
		.line-book-content-item-copy{
			display : flex;
			justify-content : flex-end;
		}
		.line-book-content-word{
			line-height : 18px;
			height : 18px;
			font-size : 14px;
		}
		.line-book-content{
			min-width : 200px;
			text-align: center;
			max-height : 60vh;
			overflow : auto;
		}
</style>