<template>
	<el-button type="text" size="mini" @click="edit">
		导入台词
		<el-dialog append-to-body :visible.sync="floatShow" title="导入台词" v-if="floatShow">
      <el-alert
        title="①导入的文件命名不可重复 ②表格首行语种必须按模板文版填写 ③语种顺序不限 ④不可有重复语种列"
        type="warning"
        :closable="false"
        style="marginBottom: 10px"
      />
			<el-card>
				<el-form label-width="100px">
					<el-form-item :label="`台词${index +1}`" v-for="(line , index) in floatData.lines" >
						<div style="display : flex;justify-content: space-between;">
							<div style="width : 300px;overflow : hidden">
								<line-upload :disabled="line.id" v-model="floatData.lines[index]" :key="floatData.lines[index].url"></line-upload>
							</div>
							
							<div>
								<el-button size="mini" type="primary" icon="el-icon-plus" @click="add(index)" v-if="floatData.lines.length < max">增加</el-button>
								<el-button size="mini" type="primary" icon="el-icon-delete" @click="remove(index)" v-if="index !== 0 || floatData.lines.length > 0">删除</el-button>
							</div>
						</div>
						
					</el-form-item>
				</el-form>
			</el-card>

			<div slot="footer">
				<el-button @click="floatShow = false">取消</el-button>
				<el-button @click="commit" type="primary">提交</el-button>
			</div>
		</el-dialog>
	</el-button>
</template>

<script type="text/javascript">
	import LineUpload from "./Upload.vue"

	export default {
		name : "line-import",
		components : {
			LineUpload
		},
		props : {
			taskId : {
				type : [Number , String],
				default : ""
			},
			max : {
				type : Number,
				default : 30
			}
		},
		data(){
			return {
				floatShow : false,
				floatData : {
					lines : [{
						name : "",
						url : ""
					}]
				}
			}
		},
		methods : {
			async edit(){
				this.floatData.lines = await this.getTaskDetail()
				this.floatShow = true;
			},
			add(index){
				this.floatData.lines.splice(index + 1 , 0 , {
					name : "",
					url : ""
				});
			},
			remove(index){
				this.floatData.lines.splice(index , 1);
				//如果为空，再添加一个空的
				if(this.floatData.lines.length === 0 ){
					this.floatData.lines = [{
						name : "",
						url : ""
					}]
				}
			},
			commit(){
				let url = `/api-intl-translate-live/translate-live-script/commit`;
				let files = this.floatData.lines.filter(v => v.url);
				if(files.length < this.floatData.lines.length){
					this.$message.warning("请检查是否所有台本已上传");
					return false;
				}
				$$.post(url , {
					files : this.floatData.lines.filter(v => v.url).map(v => ({
						id : v.id ? v.id : null,
						scriptName : v.name,
						scriptUrl: v.url
					})),
					taskId : this.taskId
				}).then(() => {
					this.$message.success("导入成功");
					this.floatShow = false;
				}).catch(err => {
					this.$message.error(err.message);
				})
			},
			async getTaskDetail(){
				let url = `/api-intl-translate-live/translate-live-script`;
				let lines = await $$.get(url , {
					params : {
						taskId : this.taskId
					}
				}).then(data => {
					return data.map(v => ({
						id : v.id , 
						name : v.name,
						url : v.scriptUrl
					}));
				}).catch(err => {
					this.$message.error(err.message);
					return false;
				})

				if(!lines || lines.length == 0)lines = [{
					name : "",
					url : ""
				}]
				return lines;
			}
		}
	};
</script>