<template>
	<div class="live-translate-task full-width">
		<search v-model="form" placeholder="任务ID、任务名称" @click="getList(true)">
			<el-form-item label="任务ID">
				<el-input type="number" v-model="form.taskId"></el-input>
			</el-form-item>

			<el-form-item label="任务名称">
				<el-input v-model="form.taskName"></el-input>
			</el-form-item>
		</search>

		<div class="live-translate-task-content table-margin">
			<el-card>
				<div slot="header" class="clearfix" shadow="always">
          <el-row type="flex" justify="space-between">
				    <el-button type="primary" size="small" @click="createDialog=true;">新建任务</el-button>
				    <a :href="`${http_pre}/api-intl-translate-live/translate-live-word/download-template`"><el-button type="text" size="samll">导入语料模板下载</el-button></a>
          </el-row>
				</div>

				<el-row>
					<el-col :span="24">
						<el-table size="mini" border :data="tableData">
							<el-table-column prop="id" label="任务ID" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="taskName" label="任务名称" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="originalLanguage" :formatter="global.utils.format.language" label="原始语种" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="translateLanguageList" :formatter="global.utils.format.language" label="翻译语种" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="statusText" label="任务状态" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="createUser" label="创建人" min-width="4%" align="center"></el-table-column>
							<el-table-column prop="createTime" :formatter="global.utils.format.date" label="任务创建时间" width="150px" align="center"></el-table-column>
							<el-table-column prop="startTime" :formatter="global.utils.format.date" label="任务开始时间" width="150px" align="center"></el-table-column>
							<el-table-column label="操作" width="260px" align="center">
								<template slot-scope="scope">
									<!-- 待开始 -->
									<template v-if="scope.row.status == 1">
										<el-button type="text" size="mini" @click="startSelf(scope.row)">手动启动任务</el-button>
										<el-button type="text" size="mini" @click="edit(scope.row)">编辑</el-button>
										<el-button type="text" size="mini" @click="config(scope.row)">任务配置</el-button>
										<line-import :taskId="scope.row.id"></line-import>
                    <el-button type="text" size="mini" @click="openTermModal(scope.row.id)">导入术语表</el-button>
									</template>
									<!-- 翻译中 -->
									<template v-else-if="scope.row.status == 2">
										<el-button type="text" size="mini" @click="complete(scope.row)">完成</el-button>
                    <el-button type="text" size="mini" @click="redistribute(scope.row)">人员分配</el-button>
										<line-import :taskId="scope.row.id"></line-import>
                    <el-button type="text" size="mini" @click="openTermModal(scope.row.id)">导入术语表</el-button>
									</template>
									<!-- 翻译完成 -->
									<template v-else-if="scope.row.status == 3">
										<!-- <el-button type="text" size="mini" @click="scriptDownload(scope.row)">下载字幕</el-button> -->
									</template>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<div class="full-width" style="text-align:center;margin-top:20px;">
					<el-pagination				
					    layout="prev, pager, next , total , sizes"
					    :total="total"
					    :page-size.sync="page_size"
					    :current-page.sync = "page"
					    >
					</el-pagination>
				</div>
			</el-card>
		</div>

		<!-- 新建编辑任务浮层 -->
			<el-dialog :visible.sync="createDialog" :title="`${createData.id ? '编辑' : '新建'}任务`">
				<el-form label-width="125px" :model="createData" :rules="rules" ref="create">
					<el-card>
						<div slot="header">
							任务配置
						</div>

						<el-form-item label="任务名称" prop="taskName">
							<el-input v-model="createData.taskName" class="item-input"></el-input>
						</el-form-item>

						<el-form-item label="原始语言" prop="originalLanguage">
							<el-radio-group v-model="createData.originalLanguage">
								<el-radio
								class="d-inline-flex justify-left align-center"
								style="width : 60px;height : 30px;"
								v-for="(item , index) in language" 
								:key="index"
								:label="item.id">
									{{item.name}}
								</el-radio>
							</el-radio-group>
						</el-form-item>

						<el-form-item label="待翻语言" prop="translateLanguageList">
							<el-checkbox-group v-model="createData.translateLanguageList">
								<el-checkbox
								:disabled="item.id == createData.originalLanguage"
								class="d-inline-flex justify-left align-center"
								style="width : 60px;height : 45px;"
								v-for="(item , index) in language"
								:key="index"
								:label="item.id">
									{{item.name}}
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>

						<el-form-item label="是否提交原始语种" prop="sendOriginalSubtitle">
							<el-radio-group v-model="createData.sendOriginalSubtitle">
								<el-radio :label="true">是</el-radio>
								<el-radio :label="false">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-card>

					<el-card class="table-margin">
						<div slot="header">
							直播流配置
						</div>

						<el-form-item label="原始直播流" prop="originLiveStreamUrl">
							<el-input v-model="createData.originLiveStreamUrl"></el-input>
						</el-form-item>

						<el-form-item label="拍词直播流" prop="rhythmLiveStreamUrl">
							<el-input v-model="createData.rhythmLiveStreamUrl"></el-input>
						</el-form-item>

						<el-form-item label="stream id" prop="streamIdList">
							<div class="add-minus-item" v-for="(item , index ) in createData.streamIdList" :key="index">
								<el-input type="number" style="width:250px" v-model="createData.streamIdList[index]"></el-input>
								<span style="display:inline-block;margin-left : 10px;" >
									<el-button v-if="createData.streamIdList.length > 1" type="primary" size="mini" @click="createData.streamIdList.splice(index , 1)">-</el-button>
									<el-button v-if="index == createData.streamIdList.length - 1 && createData.streamIdList.length < 4" type="primary" size="mini" @click="createData.streamIdList.push('')">+</el-button>
								</span>
							</div>
						</el-form-item>
					</el-card>
				</el-form>

				<div slot="footer">
					<el-button @click="createDialog = false">取消</el-button>
					<el-button type="primary" @click="commit">提交</el-button>
				</div>
			</el-dialog>
		<!-- 新建编辑任务浮层 /-->

		<!-- 任务配置浮层 -->
			<el-dialog :visible.sync="configDialog" title="任务配置">
				<el-form  label-width="140px" :model="configData" :rules="configRules" ref="config">
					<el-card>
						<div slot="header">
							任务参数
						</div>

						<el-form-item label-width="170px" label="任务开始时间" prop="startTime">
							<el-date-picker
							v-model="configData.startTime"
							type="datetime"
							placeholder="请选择时间">

							</el-date-picker>
						</el-form-item>

						<el-form-item label-width="170px" label="翻译/拍词时间间隔(s)" prop="intervalTime">
							<el-input type="number" v-model.number="configData.intervalTime" class="item-input"></el-input>
						</el-form-item>
					</el-card>

					<el-card class="table-margin">
						<div slot="header">
							人员分配
						</div>

						<el-form-item>
							<div slot="label">
								<span class="d-inline-flex" style="width : 70px">原始语种：</span>{{configData.originalLanguageName}}
							</div>
							<div class="inline">
								<el-form-item label="速记员" label-width="120px" prop="shorthander">
									<employee-suggest v-model="configData.shorthander" :staffType="8"></employee-suggest>
								</el-form-item>

								<el-form-item label="审核员" label-width="120px" prop="corrector">
									<employee-suggest v-model="configData.corrector" :staffType="11"></employee-suggest>
								</el-form-item>
							</div>
						</el-form-item>

						<el-form-item label="拍词员" label-width="120px" prop="patworder">
							<div class="add-minus-item" v-for="(item , index ) in configData.patworder" :key="index">
								<employee-suggest v-model="configData.patworder[index]" :staffType="10"></employee-suggest>
								<span style="display:inline-block;margin-left : 10px;" >
									<el-button v-if="configData.patworder.length == 2 && index === 1" type="primary" size="mini" @click="configData.patworder.splice(index , 1)">-</el-button>
									<el-button v-if="configData.patworder.length == 1 && index === 0" type="primary" size="mini" @click="configData.patworder.push('')">+</el-button>
								</span>
							</div>
							
						</el-form-item>

						<el-form-item 
						v-for="item in configData.translateLanguageListInfo" 
						:key="item.id">
							<div slot="label">
								<span class="d-flex justify-space-between">
									<span>译文：</span>
									<span>{{item.name}}</span>
								</span>
							</div>
							<div class="inline group" v-for="(group , index) in configTranslator[item.id]">
								<div class="inline">
									<el-form-item label="译员" label-width="60px">
										<employee-suggest :originalLanguage="configData.originalLanguage" :translateLanguage="item.id" v-model="configTranslator[item.id][index]" :key="configTranslator[item.id][index]" :staffType="9"></employee-suggest>
									</el-form-item>
								</div>
								
								<div class="operation">
									<el-button v-if="configTranslator[item.id].length > 1" type="primary" size="mini" icon="el-icon-minus" @click="removeGroup(item.id , index)"></el-button>
									<el-button type="primary" v-if="index == configTranslator[item.id].length - 1 && translatorMax != configTranslator[item.id].length" size="mini" icon="el-icon-plus" @click='addGroup(item.id)'></el-button>
								</div>
							</div>
							
						</el-form-item>
					</el-card>
				</el-form>

				<div slot="footer">
					<el-button @click="configDialog = false">取消</el-button>
					<el-button type="primary" @click="configCommit">提交</el-button>
				</div>
			</el-dialog>
		<!-- 任务配置浮层 /-->

    <!-- 议员上下线 -->
    <redistribute-modal :visible.sync="showRedistributeModal" :data="staffList" @success="getTaskDetail" />

    <term-import-modal :id="taskId" :visible.sync="showImportTermModal" @success="getList" />
	</div>
</template>

<script type="text/javascript">
	import {mapState} from "vuex"
	import LineImport from "./sub/LineImport.vue"
  import {getTaskById} from './service';
  import RedistributeModal from './redistributeModal';
  import TermImportModal from './sub/TermImport'

	export default {
		name : "live-translate-task",
		components : {
			LineImport,
      RedistributeModal,
      TermImportModal
		},
		data (){
			return {
				test : {},
				translatorMax : 6,
				form : {},
				page : 1,
				page_size : 10,
				tableData : [],
				total : 0,
				//创建编辑任务相关参数
				createDialog : false,
				createData : {
					originalLanguage : "",
					translateLanguageList : [],
					sendOriginalSubtitle : true,
					streamIdList : ['']
				},
				rules : {
					taskName : [
						{
							required : true,
							whitespace : true,
							message : "请填写任务名称"
						},
						{
							max : 60,
							message : "任务名称不能超过60个字"
						}
					],
					originalLanguage : [
						{
							required : true,
							message : "请选择原始语种"
						}
					],
					translateLanguageList : [
						{
							required : true,
							message : "请选择待翻语言"
						}
					],
					originLiveStreamUrl : [
						{
							required : true,
							whitespace : true,
							message : "请填写原始直播流"
						}
					],
					rhythmLiveStreamUrl : [
						{
							required : true,
							whitespace : true,
							message : "请填写拍词直播流"
						}
					],
					intervalTime : [
						{
							required : true,
							message : "请填写间隔时间"
						},
						{
							min : 30,
							message : "间隔时间必须大于30秒"
						}
					],
					streamIdList : [
						{
							required : true,
							message : "请填写至少一个streamId",
							validator : (rule , value , callback) => {
								if(value.filter(v => !!v).length == 0){
									callback(new Error());
								}else{
									callback();
								}
							}
						},
						{
							message : "存在空streamId栏，请删除或填写",
							validator : (rule , value , callback) => {
								console.log(value.filter(v => !v));
								if(value.filter(v => !v).length !== 0){
									callback(new Error());
								}else{
									callback();
								}
							}
						}
					]
				},
				//任务配置相关参数
				configDialog : false,
				configData : {
					patworder : [""]
				},
				configTranslator : {},
				configRules : {
					startTime : [
						{
							required : true,
							message : "请选择任务开始时间"
						},
						{
							message : "任务开始时间不能小于当前时间",
							validator : (rule , value , callback) => {
								if(new Date(value).getTime() < Date.now()){
									callback(new Error());
								}else{
									callback();
								}
							}
						}
					],
					intervalTime : [
						{
							required : true,
							message : "请填写时间间隔"
						},
						{
							type : "integer",
							min : 30,
							message : "间隔时间必须是大于30的整数"
						}
					],
					shorthander : [
						{
							required : true,
							message : "请选择速记员"
						}
					],
					patworder : [
						{
							required : true,
							message : "请选择拍词员",
							validator : (rule , value , callback) => {
								if(value.filter(v => !!v).length == 0){
									callback(new Error());
								}else{
									callback();
								}
							}
						},
						{
							message : "存在空拍词员栏，请删除或填写",
							validator : (rule , value , callback) => {
								console.log(value.filter(v => !v));
								if(value.filter(v => !v).length !== 0){
									callback(new Error());
								}else{
									callback();
								}
							}
						}
					],
					corrector : [
						{
							required : true,
							message : "请选择审核员"
						}
					]
				},
        taskId: '',
        showRedistributeModal: false,
        showImportTermModal: false
			}
		},
		computed : {
			...mapState ({
				language : "language"
			}),
      staffList() {
        const {translateLiveTaskStaffLanguageGroupVOS = []} = this.configData;
        return translateLiveTaskStaffLanguageGroupVOS.reduce((list, {language, translateLiveTaskStaffGroupVOS = []}) => {
          const staffGroup = translateLiveTaskStaffGroupVOS.map(item => ({...item, languageName: utils.format.language(null ,null , language)}))
          return list.concat(staffGroup)
        }, [])
      }
		},
		methods : {
			getList(reload = false){
				let url = `/api-intl-translate-live/translate-live-task/query-tasks`;
				$$.get(url , {
					params : _.extend({
						page : this.page,
						size : this.page_size
					} , this.form)
				}).then(data => {
					this.tableData = data.content.map(v => {
						let task = _.cloneDeep(v);
						
						return task;
					});
					this.total = data.totalElements;
				}).catch(err => {
					this.$message.error(err.message);
				})
			},
      async getTaskDetail() {
        const {taskId} = this;
        try {
          const data = await getTaskById(taskId)
          this.configData = _.cloneDeep(data);
          const {
            originalLanguage,
            translateLanguageList,
            shortHandTaskStaffVO,
            checkTaskStaffVO,
            translateLiveTaskStaffVOS,
            rhythmTaskStaffVOS,
            intervalTime
          } = this.configData;
          this.configData.originalLanguageName = utils.format.language(null ,null , originalLanguage);
					this.configData.translateLanguageListInfo = translateLanguageList.filter(v => !!v).map( v=> ({
						id: v,
						name: utils.format.language(null ,null , v),
					}))

					this.configData.shorthander = shortHandTaskStaffVO ? shortHandTaskStaffVO.userName : '';
					
					this.configData.corrector = checkTaskStaffVO ? checkTaskStaffVO.userName : '';
          this.configTranslator = {};
					translateLanguageList.forEach(v => {
						if(v) this.configTranslator[v] = [];
					})
					
					if (translateLiveTaskStaffVOS) {
						translateLiveTaskStaffVOS.forEach(v => {
							if (v.roleId == 9) {
								this.configTranslator[v.targetLanguage].push(v.userName);
							}
						})
          }

					//某个语言为空则插入一条空值
					translateLanguageList.forEach(v => {
						if (v) {
							if (!this.configTranslator[v] || this.configTranslator[v].length==0){
								this.configTranslator[v] = [''];
							}
						}
					})
          //拍词员重新整理为数组,如果拍词员为空，则插入一条空值
					let patworder = rhythmTaskStaffVOS &&  rhythmTaskStaffVOS.length > 0 ? rhythmTaskStaffVOS.map(v => v.userName) : [''];
					this.$set(this.configData , "patworder" , patworder);

					this.$set(this.configData , "intervalTime" , intervalTime ? parseInt((intervalTime / 1000).toFixed(0)) : '')
        } catch ({message}) {
          this.$message.error(message);
        }
      },
			//点击任务完成
			complete(row){
				this.$confirm("请确认直播任务是否已经结束，确认后将结束此次翻译" , "提示" , {
					type : "warning",
					confirmButtonText : "确认"
				}).then(()=>{
					let url = `/api-intl-translate-live/translate-live-task/finish-task`;
					$$.post(url , {
						taskId : row.id
					}).then(()=>{
						this.$message.success("操作成功");
						this.getList();
					}).catch(err => {
						this.$message.error(err.message);
					})
				}).catch(()=>{

				})
			},
			commit(){
				this.$refs.create.validate(valid => {
					if(valid){
						let url = this.createData.id ? `/api-intl-translate-live/translate-live-task/update-task` : `/api-intl-translate-live/translate-live-task/create-task`;
						let createData = _.cloneDeep(this.createData);
						
						if(this.createData.id)createData.taskId = this.createData.id
						createData.streamIdList  = createData.streamIdList.filter(v => !!v);
						$$.post(url , createData).then(() => {
							this.$message.success("操作成功");
							this.getList();
							this.createDialog = false;
						}).catch(err => {
							this.$message.error(err.message);
						})
					}
				})
			},
			edit(row){
				this.createData = _.cloneDeep(row);
				if(!this.createData.streamIdList  || this.createData.streamIdList .length == 0)this.$set(this.createData , "streamIdList" , ['']);
				this.createDialog = true;
			},
			scriptDownload(row){
				let url = `/api-intl-translate-live/translate-live-task/download-subtitle?taskId=${row.id}`;
				utils.funDownload(url);
			},
			config(row) {
        this.taskId = row.id;
        this.getTaskDetail()
				this.configDialog = true;
			},
      redistribute(row) {
        this.taskId = row.id;
        this.getTaskDetail()
        this.showRedistributeModal = true;
      },
			configCommit(){
				this.$refs.config.validate(valid => {
					if(valid){
						let url = `/api-intl-translate-live/translate-live-task/update-task`;
						let translateLiveTaskStaffList = [];
						//塞入速记员
						translateLiveTaskStaffList.push({
							roleId : 8,
							targetLanguage : this.configData.originalLanguage,
							username : this.configData.shorthander
						})
						//塞入拍词员
						this.configData.patworder.forEach(patworder => {
							if(patworder){
								translateLiveTaskStaffList.push({
									roleId : 10,
									targetLanguage : this.configData.originalLanguage,
									username : patworder
								})
							}
						})
						
						//塞入校轴员
						translateLiveTaskStaffList.push({
							roleId : 11,
							targetLanguage : this.configData.originalLanguage,
							username : this.configData.corrector
						})
						//塞入各语种译员
						let hasTranslator = true;
						_.keys(this.configTranslator).map(v => {
							this.configTranslator[v].forEach(translator => {
								if(translator){
									translateLiveTaskStaffList.push({
										roleId : 9,
										targetLanguage : v,
										username : translator
									})
								}else{
									this.$message.warning(`${utils.format.language(null , null , v)}译员至少要有一个`);
									hasTranslator = false;
								}
							})
						})
						if(!hasTranslator)return false;
						
						let data = _.cloneDeep(this.configData);
						data.translateLiveTaskStaffList = translateLiveTaskStaffList;
						data.taskId = data.id;

						//时间转为毫秒给后端
						data.intervalTime = data.intervalTime * 1000;

						$$.post(url , data).then(data => {
							this.$message.success("配置成功");
							this.getList();
							this.configDialog = false;
						}).catch(err => {
							this.$message.error(err.message);
						})
					}
				})
			},
			addGroup(language){
				this.configTranslator[language].push("");
				this.configTranslator = _.cloneDeep(this.configTranslator);
			},
			removeGroup(language ,index){
				this.configTranslator[language].splice(index , 1);
				this.configTranslator = _.cloneDeep(this.configTranslator);
			},
			startSelf(row){
				this.$confirm("是否确认启动？" , "提示" , {
					confirmButtonText : "确认"
				}).then(()=>{
					let url = `/api-intl-translate-live/translate-live-task/start-task`;
					$$.post(url , {
						taskId : row.id
					}).then(()=>{
						this.$message.success("启动成功");
						this.getList();
					}).catch(err => {
						this.$message.error(err.message);
					})
				}).catch(()=>{})
			},

      openTermModal(taskId) {
        this.taskId = taskId;
        this.showImportTermModal = true;
      }
		},
		created(){
			this.getList();
		},
		watch : {
			page (val){
				this.getList();
			},
			page_size (val){
				this.getList(true)
			},
			createDialog(val){
				if(!val){
					this.createData = {
						originalLanguage : "",
						translateLanguageList : [],
						sendOriginalSubtitle : true,
						streamIdList : ['']
					}
				}
			},
			"createData.originalLanguage"(val){
				let index = this.createData.translateLanguageList.indexOf(val);
				if(index !== -1)this.createData.translateLanguageList.splice(index , 1);
			},
			"configData.shorthander"(val){
				this.configData = _.cloneDeep(this.configData);
				if(val && this.$refs.config)this.$refs.config.validateField("shorthander");
			},
			"configData.translator"(val){
				this.configData = _.cloneDeep(this.configData);
				if(val && this.$refs.config)this.$refs.config.validateField("translator");
			},
			"configData.corrector"(val){
				this.configData = _.cloneDeep(this.configData);
				if(val && this.$refs.config)this.$refs.config.validateField("corrector");
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.el-form-item .el-form-item{
		margin-bottom : 22px;
	}

	.inline{
		display : flex;
		flex : 1;
		flex-wrap : wrap;
	}

	.group{
		.operation{
			margin-left : 5px;
		}
	}

	.item-input{
		max-width : 300px;
	}

	.add-minus-item + .add-minus-item{
		margin-top : 15px;
	}
</style>