<template>
  <el-dialog title="人员分配" :visible="visible" @close="closeModal">
    <el-table sizi="mini" :data="data">
      <el-table-column label="译文" prop="languageName"></el-table-column>
      <el-table-column label="角色">译员</el-table-column>
      <el-table-column label="用户名" prop="nickName"></el-table-column>
      <el-table-column label="状态" prop="online">
        <template slot-scope="scope">
          <el-switch activeText="上线" inactiveText="下线" :value="scope.row.online" @change="updateStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {updateStaffOnlineStatus} from './service'

export default {
  name: 'RedistributeModal',

  props: {
    data: {
      type: Array,
      default: () => []
    },

    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  methods: {
    async updateStatus({id, online}) {
      const statusText = online ? '下线' : '上线';
      try {
        const res = await updateStaffOnlineStatus({id, online: !online})
        this.$message.success(`${statusText}成功`)
        this.$emit('success', id)
      } catch ({message = `${statusText}失败`}) {
        this.$message.error(message)
      }
    },

    closeModal() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style>

</style>