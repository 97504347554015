<template>
	<div class="live-correct-page full-width">
		<div class="patworder">
			<template v-if="selectIndex >= 0 && originalData && originalData.length > 0">
				<span class="patworder-select-start">从第{{selectRow.subtitleSequence}}句开始切换成</span>
				<span class="patworder-select">
					<el-radio-group v-model="selectedPatworder">
						<el-radio v-for="(item , index) in patworderInfo" :label="item.staffUserName">{{item.staffNickname}}</el-radio>
					</el-radio-group>
				</span>
			</template>

			<template v-else>
				<span>
					当前未选中字幕
				</span>
				<span>
					<el-radio-group v-model="selectedPatworder" disabled>
						<el-radio v-for="(item , index) in patworderInfo" :label="item.staffUserName">{{item.staffNickname}}</el-radio>
					</el-radio-group>
				</span>
			</template>
			
		</div>
		<div class="section">
			<div class="section-coll player-area">
				<div class="player">
					<live-player :url="playUrl" @playerInited="playerInited"></live-player>
				</div>
			</div>
			<div class="section-colr">
				<div class="player-tip" style="text-align:center">
					<span class="player-tip-content" v-if="startTime > 0">
						离直播开始：{{global.utils.TimeFormat(Math.abs(startTime) , 1)}}
					</span>
					<span class="player-tip-content" v-else>
						直播流时间 ： {{global.utils.TimeFormat(liveTime)}}
					</span>
				</div>
				<ruler :value="subTitles" :player="videoPlayer" :begin="rulerBegin" :end="rulerEnd" />
			</div>
		</div>

		<div class="live-subtitles" v-if="originalData && originalData.length > 0">
			<div class="live-subtitle" v-for=" (subtitle , n) in originalData" :key="n">
				<el-card :class="`live-card ${n == selectIndex ? 'select' : ''}`" :body-style="{ padding: '10px' ,'padding-top' : '5px'}">
					<div @click="select(n)" class="live-card-content">
						<!-- 字幕ID -->
						<span :class="`live-card-icon ${subtitle.status === 1 ? 'pass-in' : ''} ${subtitle.status === -1 ? 'pass-out' : ''}`">{{subtitle.subtitleSequence}}</span>

						<!-- 字幕时间 -->
						<div class="live-card-time">
							<span class="live-card-time-text">开始</span>
							<span class="live-card-time-start">{{global.utils.TimeFormat(subtitle.startTime)}}</span>
							<span class="live-card-time-separator">~</span>
							<span class="live-card-time-text">结束</span>
							<span class="live-card-time-end">{{global.utils.TimeFormat(subtitle.endTime)}}</span>
						</div>

						<!-- 字幕内容 -->
						<div class="live-card-word">
							<div class="live-card-word-content">
								{{subtitle.subtitleContent}}
							</div>
						</div>

						<!-- 字幕操作按钮 -->
						<div class="live-card-action">
							<div class="live-card-action-single">
								<el-button type="primary" size="mini" class="live-card-action-button" @click.prevent="check(subtitle , 1)">通过</el-button>
							</div>
							
							<div class="live-card-action-single">
								<el-button type="primary" size="mini" class="live-card-action-button" @click.prevent="check(subtitle , -1)">不通过</el-button>
							</div>
						</div>
					</div>
				</el-card>
			</div>
		</div>

		<div v-else class="no-subtitle">
			暂无可审核字幕，请耐心等待
		</div>


	</div>
</template>

<script type="text/javascript">
	import LivePlayer from "@/components/common/LivePlayer/LivePlayer.vue"
	import Ruler from "@/components/common/Ruler.vue"
	export default {
		name : "live-correct-page",
		components : {
			LivePlayer,
			Ruler
		},
		data(){
			return {
				taskId : this.$route.query.taskId,
				startTime : -1,
				remainTime : 120,
				intervalTime : 0,
				latestTime : 0,
				intervalId : -1,
				taskInfo : {},
				taskIntervalId : -1,
				originalData : [],
				subTitles : [],
				selectIndex : 0,
				step : 4,
				playUrl : "",
				videoPlayer : {},
				rulerBegin: 0,
				rulerEnd: 10 * 1000,
				liveTime : "播放暂未开始",
				patworder : [],
				patworderInfo : [],
				selectedPatworder : "",
				patworderChanging : false
			}
		},
		computed : {
			selectRow(){
				if(this.originalData && this.originalData.length > 0 && this.originalData[this.selectIndex]){
					return this.originalData[this.selectIndex];
				}else{
					return {};
				}
			}
		},
		methods : {
			getPatwordInfo(patworder){
				let patworderInfo = [];
				if(patworder instanceof Array && patworder.length > 0){
					patworder.forEach(async v => {
						let url = `/api-intl-translate/translate-partner-staff/base-info?staffUserName=${v}`;
						let info = await $$.get(url);
						patworderInfo.push(info)
					})
				}
				this.patworderInfo = patworderInfo;
			},
			//播放器初始化事件回调
			playerInited(player){
				this.videoPlayer = player;
				//this.videoPlayer.muted(true);
			},
			getTaskInfo(){
				if(!this.taskId){
					this.$message.warning("任务无效");
					return false;
				}
				let url = `/api-intl-translate-live/translate-live-task/query-synchronize-task-info?taskId=${this.taskId}&roleId=11`;
				$$.get(url).then(data => {
					this.playUrl = data.liveStreamUrl;
					this.startTime = data.liveStartCountDown;
					this.remainTime = data.taskRemainTime || data.intervalTime;
					this.intervalTime = data.intervalTime;
					this.patworder = data.rhythmUsers;
					this.init();
				}).catch(err => {
					this.$message.error(err.message)
				})
			},
			init(){
				this.start();
				this.taskQueryStart();
			},
			taskQueryStart(){
				if(this.taskIntervalId > 0)this.taskQueryStop();
				this.taskIntervalId = setInterval(() => {
					this.getTaskQuery();
				} , 2 * 1000)
			},
			taskQueryStop(){
				clearInterval(this.taskIntervalId);
				this.taskIntervalId = -1;
			},
			getTaskQuery(){
				if(this.remainTime <= 0)return false;
				let row = _.cloneDeep(this.originalData[this.selectIndex]);
				let url = `/api-intl-translate-live/translate-live-fragment-check/query-wait-check-list`;
				$$.get(url , {
					params : {
						taskId : this.taskId
					}
				}).then(queryInfo => {
					let data = queryInfo.translateLiveWaitCheckDetailVOList ? queryInfo.translateLiveWaitCheckDetailVOList : [];

					//不在拍词员切换中就直接赋值
					if(!this.patworderChanging)this.selectedPatworder = queryInfo.defaultRhythmUser;

					let index = -1;
					if(this.selectIndex >= 0 && row){
						index = _.findIndex(data , o => o.subtitleSequence == row.subtitleSequence);
					}
					this.selectIndex = index;
					let original = _.cloneDeep(this.originalData);

					this.originalData = data.map(v => {
						let current = _.findIndex(original , o=>o.checkDetailId == v.checkDetailId);
						return current!==-1 ? original[current] : v;
					});	
					
					this.subTitles = this.originalData.map(({endTime,startTime,subtitleContent,subtitleSequence})=>{
						return {end: endTime,start: startTime,originalDialogueContent:subtitleSequence + subtitleContent , subtitleSequence : subtitleSequence}
					})
					if(this.subTitles.length > 0){
						this.rulerBegin = this.subTitles[0].start;
						this.rulerEnd = _.last(this.subTitles).end;
						if(this.rulerEnd - this.rulerBegin < 5000){
							this.rulerEnd = this.rulerBegin + 5000
						}
					}else{
						if(this.liveTime > 0){
							this.rulerBegin = this.liveTime;
							this.rulerEnd = this.rulerBegin + 10 * 1000 ;
						}else{
							this.rulerBegin = 0;
							this.rulerEnd = 10 * 1000 ;
						}
						
					}
				})
			},
			start(){
				if(this.intervalId > 0)this.stop();
				this.intervalId = setInterval(() => {
					this.startTime -= 200;

					if(this.startTime > 0){
						return false;
					}else if(this.startTime > -200){
						setTimeout(() => {
							this.start();
						} , Math.abs(this.startTime))
						return false;
					}

					this.remainTime -= 200;
					
					if(this.remainTime < 0){
						this.remainTime += this.intervalTime
					}
				} , 200)
			},
			stop(){
				clearInterval(this.intervalId);
				this.intervalId = -1;
			},
			check(row , status){
				let url =`/api-intl-translate-live/translate-live-fragment-check/push-check-result`;
				let data = {
					checkDetailId : row.checkDetailId,
					checkResult : status
				}
				row.status = status;

				$$.post(url , data).then(() => {
					//this.$message.success("操作成功");
					this.selectChange(1);
				}).catch(err => {
					this.$message.error(err.message);
				})

			},
			keyActionBind(){
				window.addEventListener("keydown", this.keyAction)
				window.addEventListener("keydown", this.keyCheck)
			},
			keyActionUnbind(){
				window.removeEventListener("keydown", this.keyAction)
				window.removeEventListener("keydown", this.keyCheck)
			},
			//快捷键上下移动
			keyAction(event){
				let key = event.key;
				switch(key){
					case "ArrowUp":
					this.selectChange(-this.step);
					break;

					case "ArrowDown":
					this.selectChange(this.step);
					break;

					case "ArrowLeft":
					this.selectChange(-1);
					break;

					case "ArrowRight":
					this.selectChange(1);
					break;

					default:
					break;
				}
			},
			//快捷键审核、快捷键切换拍词员
			keyCheck(event){
				if(event.altKey){
					let code = event.keyCode;
					let row = this.originalData[this.selectIndex];
					switch(code){
						//alt + x 审核不通过
						case 88:
						this.check(row , -1);
						break;
						//alt + z 审核通过
						case 90:
						this.check(row , 1);
						break;

						default :
						break;
					}
				}


				if(event.ctrlKey){
					let code = event.keyCode;
					switch (code) {
						case 67:
							this.switchPatWorder();
							break;
						default:
							break;
					}
				}
			},
			selectChange(num = 0){
				//不移动直接退出
				if(this.num == 0)return false;
				let absNum = Math.abs(num);
				let index = this.selectIndex + num;
				let max = this.originalData.length - 1

				//只有左右移动一次步移的时候需要判断是否到头
				if(absNum == 1){
					if(index < 0)index = 0;
					if(index > max)index = max;
				}
				//上下移动如果超过范围，直接变为无效值，不予响应
				if(0 <= index && index <= max){
					this.selectIndex = index;
				}
			},
			select(index){
				this.selectIndex = index;
			},
			updateLiveTime(){
				this.liveTime = this.videoPlayer.currentTime() * 1000;
			},
			//切换默认拍词员
			changePatWorder(){
				let url = `/api-intl-translate-live/translate-live-fragment-check/change-default-rhythm-user`;
				if(!this.originalData[this.selectIndex])return false;
				this.patworderChanging = true;
				$$.post(url , {
					taskId : this.taskId,
					rhythmUser : this.selectedPatworder,
					subtitleSequence : this.originalData[this.selectIndex].subtitleSequence
				}).then(()=>{
					this.$message.success("切换默认拍词员成功");
					this.patworderChanging = false;
					//切换成功后重新获取审核条目
					this.getTaskQuery();
				}).catch(err => {
					this.$message.error(err.message);
					this.patworderChanging = false;
				})
			},
			//模拟手动切换拍词员
			switchPatWorder(){
				//有数据，且有数据被选中时，快捷键生效
				if(this.selectIndex >= 0 && this.originalData && this.originalData.length > 0){
					let index = _.findIndex(this.patworderInfo , v=> v.staffUserName == this.selectedPatworder);
					index ++ ;
					//超出数组范围则变成第一个
					if(index >= this.patworderInfo.length)index = 0;
					//手动设置radio的值
					this.selectedPatworder = this.patworderInfo[index].staffUserName;
				}
			}
		},
		mounted(){
			this.getTaskInfo();
			this.keyActionBind();
		},
		watch : {
			videoPlayer(n , o){
				if(JSON.stringify(o) == "{}"){
					this.videoPlayer.on("timeupdate" , this.updateLiveTime);
				}
			},
			patworder(val){
				this.getPatwordInfo(val);
			},
			selectedPatworder(val){
				if(this.selectedPatworder >= 0){
					this.changePatWorder();
				}
			}
		},
		//组件销毁之前清楚键盘事件，避免多次绑定
		beforeDestroy(){
			this.keyActionUnbind();
			if(this.videoPlayer)this.videoPlayer.off("timeupdate" , this.updateLiveTime);
			this.stop();
			this.taskQueryStop();
		}
	};
</script>

<style lang="scss" scoped="">
	.live-correct-page{
		.patworder{
			height : 50px;
			line-height : 50px;
			.patworder-select{
				margin-left : 15px;
			}
		}

		.section{
			display: flex;
			.player-area{
				flex: 0 0 auto;
				width:300px;	
			}
			.section-colr{
				flex:1 1 auto;
				margin-left:20px;
			}
			.player-tip{
				.player-tip-content{
					line-height : 35px;
					height : 35px;
					display: inline-block;
					width : 100%;
					text-align : center;
					border : 1px solid #999;
					margin-bottom : 5px;
				}
			}	
		}		

		.live-subtitles{
			display : flex;
			flex-wrap: wrap;
			.live-subtitle{
				width : 25%;
				.live-card{
					margin : 10px;
					position : relative;

					&.select{
						background-color : #deddb7;
					}

					.live-card-content{
						height : 150px;
						
						.live-card-icon{
							position: absolute;
							left : 0;
							top :0;
							display : inline-block;
							width : 50px;
							height : 30px;
							line-height : 30px;
							text-align : center;
							background-color: red;
							&.pass-in{
								background-color : green;
							}

							&.pass-out{
								background-color : red;
							}
						}

						.live-card-time{
							margin-left : 45px;
							margin-right : 55px;
							height : 30px;
							font-size : 12px;
							.live-card-time-start , .live-card-time-end{
								text-align : center;
								padding : 0 5px;
								float : left;
								width : 45%;
								box-sizing : border-box;
							}
							.live-card-time-separator{
								float : left;
								width : 10%;
								text-align:center;
							}
							.live-card-time-text{
								float : left;
								display : none;
							}
							@media screen and (min-width : 1830px){
								.live-card-time-text{
									display : block;
									width : 11%;
								}
								.live-card-time-start , .live-card-time-end{
									width : 33%;
								}
							}
						}
						@media screen and (min-width : 1450px){
							.live-card-time{
								font-size : 15px;
							}
						}

						@media screen and (min-width : 1840px){
							.live-card-time{
								font-size : 16px;
							}
						}
						

						.live-card-word{
							text-align : center;
							display : flex;
							height : 120px;
							justify-content : center;
							align-items: center;
						}

						.live-card-action{
							position : absolute;
							right : 0;
							top : 0;
							width : 60px;
							.live-card-action-single{
								width : 60px;
							}
							.live-card-action-button{
								display : block;
								width : 60px;
								margin : 5px 0;
							}
						}
					}
				}
			}
		}

		.no-subtitle{
			height : 100px;
			line-height : 100px;
			text-align : center;
		}
	}
</style>