<template>
	<div class="live-my-employee full-width d-flex">
		<div class="live-my-employee-content full-width">
			<div>
				<div class="cooperation-title">
					<span class="cooperation-table-name">直播任务</span>
				</div>

				<el-table :data="tableData" align="center">
					<el-table-column
						prop="id"
						label="任务ID"
						min-width="3%"
					>
					</el-table-column>

					<el-table-column
						prop="taskName"
						label="任务名称"
						min-width="8%"
					>
					</el-table-column>

					<el-table-column
						prop="originalLanguage"
						label="原始语言"
						:formatter="global.utils.format.language"
						min-width="6%"
					>
					</el-table-column>

					<el-table-column
						prop="translateLanguageList"
						label="待翻语言"
						:formatter="global.utils.format.language"
						min-width="6%"
					>
					</el-table-column>

					<el-table-column
						prop="workRoleId"
						label="角色"
						min-width="8%"
					>
						<template slot-scope="scope">
							<template v-for="roleId in scope.row.workRoleIdList">
								{{roles[roleId]}}
							</template>
						</template>
					</el-table-column>

					<el-table-column
						min-width="4%"
						label="操作">
						<template slot-scope="scope">
							<template v-for="roleId in scope.row.workRoleIdList">
								<router-link v-if="roleId == 8" :to="{name : 'live-shorthand-page' , query : {taskId : scope.row.id}}">
									<el-button type="text">速记</el-button>
								</router-link>

								<router-link v-if="roleId == 9" :to="{name : 'live-translate-page' , query : {taskId : scope.row.id}}">
									<el-button type="text">翻译</el-button>
								</router-link>

								<router-link v-if="roleId == 10" :to="{name : 'live-patword-page' , query : {taskId : scope.row.id}}">
									<el-button type="text">拍词</el-button>
								</router-link>

								<router-link v-if="roleId == 11" :to="{name : 'live-correct-page' , query : {taskId : scope.row.id}}">
									<el-button type="text">校对</el-button>
								</router-link>
							</template>
						</template>
					</el-table-column>
				</el-table>

				<div class="text-center" v-if="length > 0">
				    <v-pagination
				      v-model="page"
				      :length="length"
				    ></v-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		name : "live-my-employee",
		data(){
			return {
				page : 1,
				page_size : 10,
				length : 0,
				tableData : [],
				roles : {
					8 : "速记员",
					9 : "翻译员",
					10 : "拍词员",
					11 : "校轴员"
				}
			}
		},
		methods : {
			getList(reload = false){
				let url = `/api-intl-translate-live/translate-live-task/query-worker-tasks`;
				$$.get(url , {
					params :{
						page : this.page,
						size : this.page_size
					}
				}).then(data => {
					this.tableData = data.content.map(v => {
						let task = _.cloneDeep(v);
						return task;
					});
					this.length = data.totalPages;
				}).catch(err => {
					this.$message.error(err.message);
				})
			}
		},
		mounted(){
			this.getList();
		},
		watch : {
			page(){
				this.getList();
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.live-my-employee-content{
		max-width : 1500px;
	}
	.cooperation-title {
		margin : 10px 0;
		.cooperation-table-name{				
			font-weight : bold; 
			
		}
		.select {
			color : red;
		}
		.more {
			color : blue;
			margin-left : 10px;
			cursor : pointer;
		}

		.interval {
			float : right;
			color : blue;
			word-spacing:10px;
			cursor : pointer;
		}
	}	
	.cooperation-table {
		margin-bottom : 20px;
	}	
</style>