const BASE_URL = '/api-intl-translate-live'

// 获取任务详情
export const getTaskById = taskId => $$.get(
  `${BASE_URL}/translate-live-task/query-task-by-id`,
  {
    params: {taskId}
  }
)

/**
 * 议员上下线
 * @param {number} id 
 * @param {boolean} online 在线状态
 */
export const updateStaffOnlineStatus = data => $$.post(
  `${BASE_URL}/translate-live-task/update-staff-online-status`,
  data
)

/**
 * 导入术语表
 * @param {array} files
 * @param {number} taskId
 */
export const importTermFile = data => $$.post(
  `${BASE_URL}/translate-live-word/commit`,
  data
)

/**
 * 获取术语表
 * @param {number} taskId
 */
export const getTermFile = taskId => $$.get(
  `${BASE_URL}/translate-live-word/query-by-task-id`,
  {params: {taskId}}
)

/**
 * 获取词库提示
 * @param {number} taskId
 * @param {number} fragmentId
 */
export const getTermWordTip = params => $$.get(
  `${BASE_URL}/translate-live-fragment-translate/query-word-tip`,
  {params}
)
