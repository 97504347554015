<template>
	<div class="live-shorthand-page full-width">

		<div class="player-area">
			<div class="player-tip"><span class="player-tip-content">直播流时间 ： {{global.utils.TimeFormat(Math.abs(currentTime))}}</span></div>
			<div class="player">
				<live-player :url="playUrl" @playerInited="playerInited"></live-player>
                <patwords v-model="captionStaging"  @patItemWordEnd="patItemWordEnd" @afterTurnPage="afterTurnPage" :player="videoPlayer" :totalTime="0" mode="live" />
			</div>
		</div>
		

		<div class="text-area">
			<el-tabs type="card" value="first">
				<el-tab-pane :label="`原始语言：${originalLanguageName}`" name="first" class="ciku">
                <!-- table-wrap -->
                <div class="table-wrap">
                    <div class="table-head">
                        <table class="table">
                        <colgroup>
                            <col width="40" />
                            <col width="80" />
                            <col width="80" />
                            <col width="140" />
                        </colgroup>
                        <thead>
                            <tr class="table-row">
                                <th></th>
                                <th>起始时间</th>
                                <th>结束时间</th>
                                <th>原文</th>
                            </tr>
                        </thead>
                        </table>
                    </div>
                    <div class="table-body">
                        <table class="table">
                        <colgroup>
                            <col width="40" />
                            <col width="80" />
                            <col width="80" />
                            <col width="140" />
                        </colgroup>
                        <tbody>
                            <tr class="table-row" v-for="(item, index) in captionStaging" :key="index">
                                <td>{{item.subtitleSequence}} </td>
                                <td>
                                    <div :class="[item.patwordsEdit==='start' && 'pattip']">
                                    {{ item.start ?  global.utils.TimeFormat(Math.abs(item.start)) : '--:--:--:---' }}
                                    </div>
                                </td>
                                <td>
                                    <div :class="[item.patwordsEdit==='end' && 'pattip']">
                                    {{ item.end ?  global.utils.TimeFormat(Math.abs(item.end)) : '--:--:--:---' }}
                                    </div>
                                </td>
                                <td>{{ item.subtitleContent }}</td>
                            </tr>
                            <tr class="table-row" v-if="captionStaging.length ===0 ">
                                <td colspan="4"><div style="text-align:center;padding:20px;">请稍等，一大波字幕正在来的路上.......</div></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                <!-- /table-wrap -->
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
import LivePlayer from "@/components/common/LivePlayer/LivePlayer.vue"
import Patwords from "@/components/common/Patwords.vue"
export default {
    name: "live-patword",
    data(){
        return {
            taskId : this.$route.query.taskId,
            maxWaitRhythmId: null,
            currentTime:0,
            captionStaging:[],
            subtitles:[],
            videoPlayer : {},
            timeoutId: 0,
            playUrl: "", // rtmp://10.61.228.28/live/ls_hobbit_cs_default
            originalLanguageName:'',

            disappearType: 1, // 1 拍过且字幕结束时间效果当前刻度尺开始时间的都清除掉， 2 为拍一条消失一条
            patedCache: [],
            clearPatedTime: null
        }
    },
    components : {
        LivePlayer,
        Patwords
    },
    created(){
        this.getTaskInfo();
        this.getWaitRhythmDetailList()
    },
    watch:{
		subtitles: {
			immediate: true,
			handler(v) {
				this.captionStaging = v.map(v=>{
                    const { 
                            rhythmDetailId: id, 
                            subtitleContent: originalDialogueContent
                        } = v

                    const newAttributesMap={ id, originalDialogueContent,translateDialogueContent: originalDialogueContent}

                    return {
                        ...newAttributesMap,
                        ...v
                    }
                })
			}
		}
    },
    methods:{
        // 拍词组件回调： 播放器初始化事件回调
        playerInited(player){
            this.videoPlayer = player
            this.videoPlayer.on('timeupdate', ()=>{
                const currentTime = player.currentTime()
                this.currentTime = currentTime * 1000
            })
        },
        // 拍词组件回调：刻度翻页后清楚掉上一页已经拍完的字幕数据
        afterTurnPage(){
            const { setPatingInfo, subtitles, disappearType } = this
            if( disappearType!==1 ) return false 
            /**
             * 移除的条件：对于已经拍过的且以不在当前刻度时间戳之内的
             */
            this.subtitles = setPatingInfo(subtitles).filter(v=>{
                return (v.pated === true && !v.left) ===  true ? false : true
            })
        },

        /**
         * 字幕被拍完后即消失
         */
        oneByOne(id){
            const { disappearType, setPatingInfo, subtitles} = this
            if( disappearType!==2 ) return false 
            this.patedCache.push(id)
            this.clearPatedTime && clearTimeout(this.clearPatedTime)
            this.clearPatedTime = setTimeout(()=>{
                this.subtitles = setPatingInfo(subtitles).filter(v=>{
                    return this.patedCache.includes(v.rhythmDetailId) ? false : true
                })
                this.patedCache = []
            }, 1000)
        },
        async patItemWordEnd(item){
            item.startTime = item.start
            item.endTime = item.end
            await this.pushRhythmResult({ ...item })
            this.oneByOne(item.rhythmDetailId)
        },
        async pushRhythmResult(data){
            if(!this.taskId){
                this.$message.warning("任务无效");
                return false;
            }
            const { 
                startTime, 
                endTime, 
                rhythmDetailId 
            } = data

            if(!endTime || !startTime || endTime < startTime || !rhythmDetailId) return false
            let url = `/api-intl-translate-live/translate-live-fragment-rhythm/push-rhythm-result`;
            try{
                await $$.post(url, { startTime, endTime, rhythmDetailId })
            }catch(err){
                this.$message.error(err.message)
            } 
        },
        pollingData(){
            this.timeoutId > 0 && clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(()=>{
                this.getWaitRhythmDetailList()
            }, 2000)
        },
        setPatingInfo(data){
            const { captionStaging } = this
            let maxId = 0
            let result = []
            result = data.map(value=>{
                const pating = captionStaging.find( v=> value.rhythmDetailId === v.rhythmDetailId )
                maxId = maxId > value.rhythmDetailId ? maxId : value.rhythmDetailId
                if(pating){
                    pating.pated && delete value.patwordsEdit;
                    !pating.left && delete value.left;
                }
                return pating ? { ...value, ...pating } : value
            })
            this.maxWaitRhythmId = maxId
            return result
        },
        getWaitRhythmDetailList(){
            const { taskId, maxWaitRhythmId } = this
            if(!taskId){
                this.$message.warning("任务无效");
                return false;
            }
            let url = `/api-intl-translate-live/translate-live-fragment-rhythm/query-wait-rhythm-detail-list`;
            $$.get(url,  {
                    params:{
                        taskId,
                        maxWaitRhythmId
                    }
                }
            ).then(data => {
                if(!data || (data && data.length === 0)) return false
                this.subtitles = this.setPatingInfo([
                    ...this.subtitles, 
                    ...data
                ])
            }).catch(err => {  
                this.$message.error(err.message)
            })
            this.pollingData()
        },
        getTaskInfo(){
            const { taskId } = this
            if(!taskId){
                this.$message.warning("任务无效");
                return false;
            }
            let url = `/api-intl-translate-live/translate-live-task/query-synchronize-task-info?taskId=${this.taskId}&roleId=10`;
            $$.get(url,{
                params:{
                    taskId
                }
            }).then(data => {
                this.playUrl = data.liveStreamUrl;
                this.originalLanguageName = utils.format.language(null , null , data.originalLanguage);
            }).catch(err => {
                this.$message.error(err.message)
            })   
        }
    },
    destroyed(){
        this.timeoutId > 0 && clearTimeout(this.timeoutId)
    }
}
</script>
<style lang="scss">
	.live-shorthand-page{
		display : flex;
		.player-area{
			width : 750px;
            flex: 0 0 auto;
			.player-tip{
				.player-tip-content{
                    display: block;
					line-height : 40px;
					height : 40px;
					text-align : center;
					border : 1px solid #666;
					margin-bottom : 5px;
				}
			}		
		}
		.text-area{
			margin-left : 15px;
			flex : 1 1 auto;
			.textarea {
				margin : 0 15px 0 0;
			}
		}	
		.el-tabs__header{
			margin-bottom : 0;
		}
    }

    @media screen and (min-width: 1367px) {
        .live-shorthand-page{
            .player-area{
                width : 950px;
            }
        }
    }

	/**表格模块**/
	.table-wrap{
		text-align: left;
		line-height: 18px;
		.table-head{
			text-align: left;
			th{
                font-size: 14px;
				padding: 10px 16px;
				background: #fafafa;
				border: 1px solid #e8e8e8;
				border-bottom:0;
			}
        }
        .table-body{
            font-size:14px;
        }
		table{
			width:100%;
			border-collapse:collapse;
			border-spacing:0;
			table-layout: fixed;
			td{
				padding: 10px 16px;
				word-break: break-word;
				overflow-wrap: break-word;
				border: 1px solid #e8e8e8;
			}
			tr{
				background-color: #fff;
				transition: all 0.3s;
				&:hover , &.active{
					background-color: #e6f7ff;
				}
				&success{
					background-color: #yellow;
				}
				&error{
					background-color: #red;
				}
			}
		}
    }
	.pattip {
		position: relative;
		display: inline-block;
		vertical-align: top;
		&:after{
			position: absolute;
			width:10px;
			height:6px;
			left:0;
			bottom:-2px;
			content: '.';
			background:#19ce40;
			animation:pattipAnima 1s linear infinite;
			text-indent: -999px;
			z-index: 2;
			overflow: hidden;
		}
		&:before{
			position: absolute;
			width:100%;
			height: 2px;
			left:0;
			bottom:0;
			content: '.';
			background:#00be06;
			text-indent: -999px;
			z-index: 1;
			overflow: hidden;
		}
	}

	@keyframes pattipAnima {
		0% {
			left:0;
		}
		50%{
			left:90%;
		}
		to {
			left:0;
		}
	}
</style>