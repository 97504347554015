<template>
	<div class="live-translate-page full-width">
    <el-row type="flex" :gutter="20">
      <el-col :span="16">
        <div class="player-area">
          <div class="player-tip">
            <template>
              <span class="player-tip-content" v-if="startTime > 0">
                离直播开始：{{global.utils.TimeFormat(Math.abs(startTime) , 1)}}
              </span>

              <span class="player-tip-content" v-else>
                提交任务倒计时 ： {{global.utils.TimeFormat(Math.abs(remainTime) , 1)}}
              </span>
            </template>
          </div>
        </div>

        <div class="text-areas">
          <div class="text-area">
            <el-tabs type="card" value="first">
              <el-tab-pane :label="`原始语言：${originalLanguageName}`" name="first" class="ciku">
                <el-input readonly v-model="original" class="textarea" type="textarea" :autosize="{ minRows: 17, maxRows: 17}"></el-input>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="text-area">
            <el-tabs type="card" value="first">
              <el-tab-pane :label="`${translateLanguageName}译文`" name="first" class="ciku">
                <el-input @keydown.native="keydownInput" @keyup.native="keyupInput" @mouseup.native="mousedownInput" ref="input" v-model="input" class="textarea" type="textarea" :autosize="{ minRows: 17, maxRows: 17}"></el-input>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="term-tip">本句术语提示</div>
        <el-table border size="small" :data="currentLineTermList">
          <el-table-column :label="originalLanguageName" align="center" prop="originalWord">
            <template slot-scope="scope">
              <div>
                <span class="key-num">{{scope.$index + 1}}</span>
                <span>{{scope.row.originalWord}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="translateLanguageName" align="center" prop="translateWord"></el-table-column>
        </el-table>
        <div class="hot-key-tip">粘贴快捷方式：ALT + 序号</div>
      </el-col>
    </el-row>
		
	</div>
</template>

<script type="text/javascript">
	import LivePlayer from "@/components/common/LivePlayer/LivePlayer.vue"
  import {getTermWordTip} from './service';

	export default {
		name : "live-translate-page",
		components : {
			LivePlayer
		},
		data(){
			return {
				original : "",
				input : "",
				taskId : this.$route.query.taskId,
				startTime : -1,
				remainTime : 120,
				intervalTime : 0,
				latestTime : 0,
				intervalId : -1,
				taskInfo : {},
				originalLanguageName : "",
				translateLanguageName : "",
				taskIntervalId : -1,
				originalData : [],
				subTitles : [],
        termList: [],
        currentLineTermList: [],
        rowIndex: null
			}
		},
		methods : {
			getTaskInfo(){
				if(!this.taskId){
					this.$message.warning("任务无效");
					return false;
				}
				let url = `/api-intl-translate-live/translate-live-task/query-synchronize-task-info?taskId=${this.taskId}&roleId=9`;
				$$.get(url).then(data => {
					this.startTime = data.liveStartCountDown;
					this.remainTime = data.taskRemainTime || data.intervalTime;
					this.intervalTime = data.intervalTime;
					this.originalLanguageName = utils.format.language(null , null , data.originalLanguage);
					this.translateLanguageName = utils.format.language(null , null , data.translateLanguage);
					this.init();
				}).catch(err => {
					this.$message.error(err.message)
				})
			},
      async getTermWordTip() {
        const {taskId, fragmentId} = this;
        this.currentLineTermList = [];
        if (fragmentId) {
          const {detailList = []} = await getTermWordTip({taskId, fragmentId})
          this.termList = detailList;
        } else {
          this.termList = []
        }
      },
			init(){
				this.start();
				this.taskQueryStart();
			},
			taskQueryStart(){
				if(this.taskIntervalId > 0)this.taskQueryStop();
				this.taskIntervalId = setInterval(() => {
					this.getTaskQuery();
				} , 2 * 1000)
			},
			getTaskQuery(){
				if(this.remainTime <= 0)return false;
				let url = `/api-intl-translate-live/translate-live-fragment-translate/query-wait-translate-list`;
				$$.get(url , {
					params : {
						taskId : this.taskId
					}
				}).then(data => {
					this.originalData = data.map(v => {
						v.start = v.startCountdownTime + Date.now();
						v.end = v.endCountdownTime + Date.now();
						return v;
					});
					localStorage.setItem("translateData" , JSON.stringify(this.originalData))
					//if(this.input == "")this.originalConvert();
				})
			},
			originalConvert(){
				let data = this.originalData.length == 0 ? JSON.parse(localStorage.getItem("translateData")) : this.originalData;
				data = data || [];
				let fragment = data.filter(v => v.start <= Date.now() && v.end > Date.now());
				this.subTitles = fragment.length > 0 ? fragment[0].translateLiveWaitTranslateDetailList : [];
				this.fragmentId = fragment.length > 0 ? fragment[0].fragmentId : '';
				this.original = fragment.length > 0 ? fragment[0].translateLiveWaitTranslateDetailList.map(v => `${v.subtitleSequence}. ${v.subtitleContent}`).join("\n") : "";
				if(this.input == ""){
					this.input = this.subTitles.length > 0 ? this.setOriginalTranslateInput() : "";
					
					this.$refs.input.focus();
					this.$nextTick(() => {
						this.$refs.input.$el.children[0].selectionStart = this.input.indexOf("\n");
						this.$refs.input.$el.children[0].selectionEnd = this.input.indexOf("\n");
					})
				}else{
					this.resetTranslate();
				}
        if (this.fragmentId) {
          this.getTermWordTip()
        }
			},
			resetTranslate(){
				//无待翻字幕，译文为空
				if(this.subTitles.length == 0)this.input = "";
				else {
					//若原文和译文ID不匹配，则重置
					let ori = parseInt(this.subTitles[0].subtitleSequence);
					let translate = parseInt(this.input.slice(0 , this.input.indexOf(".")));
					
					if(ori !== translate){
						this.input = this.subTitles.length > 0 ? this.setOriginalTranslateInput() : "";
						this.$refs.input.focus();
						this.$nextTick(() => {
							this.$refs.input.$el.children[0].selectionStart = this.input.indexOf("\n");
							this.$refs.input.$el.children[0].selectionEnd = this.input.indexOf("\n");
						})
					}
				}				
			},
			//渲染初始译文数据
			setOriginalTranslateInput(){
				let input = this.subTitles.map(v => v.subtitleSequence + "." + (v.translateContent ? v.translateContent : "")).join("\n");
				return input;
			},
			taskQueryStop(){
				if(this.taskIntervalId > 0)clearInterval(this.taskIntervalId);
				this.taskIntervalId = -1;
			},
			start(){
				this.originalConvert();
				if(this.intervalId > 0)this.stop();
				this.intervalId = setInterval(() => {
					this.startTime -= 200;

					if(this.startTime > 0){
						return false;
					}else if(this.startTime > -200){
						setTimeout(() => {
							this.start();
						} , Math.abs(this.startTime))
						return false;
					}

					this.remainTime -= 200;
					
					if(this.remainTime < 0){
						setTimeout(() => {
							this.send();
						} , Math.abs(this.remainTime))

						this.remainTime += this.intervalTime
					}
				} , 200)
			},
			stop(){
				if(this.intervalId > 0)clearInterval(this.intervalId);
				this.intervalId = -1;
			},
			send(){
				let url = `/api-intl-translate-live/translate-live-fragment-translate/push-translate-result`;
				let input = this.input.split("\n");
				let list = this.subTitles.map((v , k) => {
					if(input[k]){
						let index = input[k].indexOf(".");
						v.translateResult = input[k].slice(index + 1);
					}else{
						v.translateResult = "";
					}
					
					return v;
				})

				this.input = "";
				this.originalConvert();
				
				$$.post(url , list).then(()=>{
					//提交完成后再次校准时间
					this.getTaskInfo();
				}).catch(err => {
					//this.$message.error(err.message);
				})
			},
			keydownInput(e){
				let key = e.key;
				let list = this.input.split("\n");
				let target = e.target;
				//如果原始内容为空，键盘输入不生效
				if(this.original == ""){
					e.preventDefault();
				}
				//光标非全选情况
				if(target.selectionStart === target.selectionEnd){
					//如果光标在标号中间操作，除了光标移动以外任何键盘操作都不允许
					//有.没有换行或者换行在.的后面，证明当前光标在数字标号中间
					let directionKey = ["ArrowUp" , "ArrowDown" , "ArrowLeft" , "ArrowRight"];
					if(!_.includes(directionKey , key)){
						if(this.isInNumber(target.selectionStart)){
							e.preventDefault();
							return false;
						}
					}

					//按下回车的操作
					if(key == "Enter"){
						//在句子的最末尾回车，添加新的一行
						if(target.selectionStart == this.input.length){
							if(list.length < this.subTitles.length){
								let code = this.subTitles[list.length].subtitleSequence;
								this.input += `\n${code}.`
								e.preventDefault();
							}
						}else if(target.selectionStart < this.input.length){
							let input = this.input.split("");
							input.splice(target.selectionStart , 0 , "\n1111.");
							
							let input_str = input.join("").split("\n").map((v , k) => {
								let index = v.indexOf(".");
								let pre = this.subTitles[k] ? this.subTitles[k].subtitleSequence + "." : ''
								return pre + v.slice(index + 1);
							});
							
							this.input = input_str.join("\n");
							let selectIndex = this.input.indexOf("\n" , target.selectionStart + 1);
							//在下一个时间分片重新分配输入框光标
							this.$nextTick(() => {
								target.selectionStart = selectIndex;
								target.selectionEnd = selectIndex;
							})
							e.preventDefault();
						}
						
					}

					if(key == "Backspace"){
						//如果删除时只剩下一个标号，不允许删除
						if(target.selectionStart == this.input.indexOf(".") + 1){
							e.preventDefault();
							return false;
						}

						//在.后面删除时，有内容无反应，没有内容删除当前行
						if(this.isInNumber(target.selectionStart - 1)){
							let words = this.wordSplit();
							let line_index = this.getLineIndex(target.selectionStart);
							
							//在当前行没有内容时才删除
							if(words[line_index] == ""){
								words.splice(line_index , 1);
								this.resetNumberSet(words , target);
							}

							e.preventDefault();
							return false;
						}						
					}

					if(key == "Delete"){
						//在标号内或在行末，禁用delete
						if(this.isInNumber(target.selectionStart) || this.input[target.selectionStart] == "\n"){
							e.preventDefault();
							return false;
						}
					}
				}else{
					//全选情况只支持删除，其他按键无效
					if(key == "Backspace"){
						let line_start = this.getLineIndex(target.selectionStart);
						let line_end = this.getLineIndex(target.selectionEnd);
						let inNumberStart = this.isInNumber(target.selectionStart);
						let inNumberEnd = this.isInNumber(target.selectionEnd);

						//选择未跨行的情况
						if(line_start == line_end){
							//均在标号内无响应
							if(inNumberStart && inNumberEnd){
								e.preventDefault();
								return false;
							}else if(inNumberStart){
								//仅开头在标号内，移动光标到.后方，然后使用默认行为删除
								target.selectionStart = this.input.indexOf("." , target.selectionStart) + 1;
							}
						}else{
							//选择跨行的情况
							let start = target.selectionStart;
							//如果起始光标在标号内，则上推到上一个换行
							if(inNumberStart){
								start = this.input.lastIndexOf("\n" , target.selectionStart);
								if(start === -1)start = 0;
							}
							
							//如果结束光标在标号内，也上推到上一个换行
							let end = target.selectionEnd;
							if(inNumberEnd){
								end = this.input.lastIndexOf("\n" , target.selectionEnd);
								if(end === -1)end = 0;
							}

							let left = this.input.slice(0 , start);
							let right = this.input.slice(end);
							let words = this.wordSplit([left , right].join("\n"));
							this.resetNumberSet(words , target);
							e.preventDefault();
						}
					}else if(e.ctrlKey && e.keyCode == 67){
						let line_start = this.getLineIndex(target.selectionStart);
						let line_end = this.getLineIndex(target.selectionEnd);
						if(line_start !== line_end){
							e.preventDefault();
						}
					}else{
						e.preventDefault();
					}
				}				
			},
      keyupInput(e) {
        const {altKey, keyCode, target: {selectionStart, selectionEnd}} = e;
        if (selectionStart === selectionEnd) {
          this.rowIndex = this.getLineIndex(selectionStart);
          if (altKey && keyCode >= 49 && keyCode <= 57 && !this.isInNumber(selectionStart)) {
            e.preventDefault();
            this.fastInput(e)
          }
        }
      },
      mousedownInput(e) {
				let target = e.target;
        if (target.selectionStart === target.selectionEnd) {
          this.rowIndex = this.getLineIndex(target.selectionStart);
        }
      },
			//按换行重新调整排序编号
			resetNumberSet(words , target){
				let input_str = words.map((v , k) => {
					let pre = this.subTitles[k] ? this.subTitles[k].subtitleSequence + "." : ''
					return pre + v;
				});
				this.input = input_str.join("\n");
				let selectIndex = this.input.indexOf("\n" , target.selectionStart + 1);
				//在下一个时间分片重新分配输入框光标
				this.$nextTick(() => {
					target.selectionStart = selectIndex;
					target.selectionEnd = selectIndex;
				})
			},
			//判断是否在标号内
			isInNumber(index){
				let current_line_index = this.getCurrentLineIndex(index);
				let current_line = this.getLineIndex(index);
				let words = this.input.split("\n");
				let current_word = words[current_line];

				if(current_word.indexOf(".") + 1 <= current_line_index){
					return false;
				}else {
					return true;
				}
			},
			//将当前输入分词
			wordSplit(write){
				let input = write ? write.split("\n") : this.input.split("\n");
				return input.map(v => {
					let index = v.indexOf(".");
					let id = v.slice(0 , index);
					let content = v.slice(index + 1);
					return content;
				})
				return result;
			},
			//获取当前光标所在的行数
			getLineIndex(selectIndex){
				let input = this.input.split("\n");
				let index = -1;
				let length = 0;
				input.some((v , k) => {
					length += v.length;
					//少算了一个\n的长度，需要补回来
					length ++;
					if(length >= selectIndex){
						index = k;
						return true;
					}
				})

				return index;
			},
			//获取当前光标在当前行的index
			getCurrentLineIndex(selectIndex){
				let input = this.input.split("\n");
				let index = selectIndex;
				input.some(v=>{
					if(index > v.length){
						index -= v.length;
						//少算了一个\n的长度，需要补回来
						index --;
					}else{
						return true;
					}
				})
				return index;
			},
			windowVisibleEventBind(){
				document.addEventListener("visibilitychange" , this.windowVisibleChange)
        // document.addEventListener('keydown', this.fastInput)
			},
			windowVisibleEventUnbind(){
				document.removeEventListener("visibilitychange" , this.windowVisibleChange)
        document.removeEventListener('keydown', this.fastInput)
			},
			windowVisibleChange(){
				if(!document.hidden){
					this.getTaskInfo();
				}
			},
      fastInput(e) {
        const {keyCode, target} = e;
        const {currentLineTermList, input} = this;
        const keyNum = keyCode - 49;
        const startIndex = target.selectionStart;
        const insertStr = currentLineTermList[keyNum];
        if (insertStr) {
          this.input = `${input.slice(0, target.selectionStart)}${insertStr.translateWord}${input.slice(target.selectionStart)}`
          this.$nextTick(() => {
            const length = insertStr.translateWord.length
            e.target.selectionStart = startIndex + length;
            e.target.selectionEnd = startIndex + length;
          })
        }
      }
		},
    watch: {
      rowIndex(newValue, oldValue) {
        const {termList} = this;
        if ((newValue || newValue === 0) && termList.length && newValue !== oldValue) {
          this.currentLineTermList = termList[newValue] ? termList[newValue].detailList : [];
        }
      }
    },
		mounted(){
			this.getTaskInfo();
			this.$refs.input.focus();
			this.windowVisibleEventBind();
		},
		beforeDestroy(){
			this.stop();
			this.taskQueryStop();
			this.windowVisibleEventUnbind();
		}
	};
</script>

<style lang="scss">
	.live-translate-page{
		.player-area{
			.player-tip{
				.player-tip-content{
					line-height : 60px;
					height : 60px;
					display: inline-block;
					width : 100%;
					text-align : center;
					border : 1px solid #666;
					margin-bottom : 5px;
				}
			}		
		}

		.text-areas{
			display : flex;
			.text-area +.text-area{
				margin-left : 15px;
			}
			.text-area{
				flex : 1 1 auto;
				.textarea {
					margin : 0 15px 0 0;
				}
			}	
			.el-tabs__header{
				margin-bottom : 0;
			}
		}
		
    .term-tip {
      color: #fff;
      text-align: center;
      line-height: 60px;
      background: #3f51b5;
      margin-bottom: 5px;
    }

    .key-num {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #fff;
      background: #3f51b5;
      padding: 0 6px;
    }

    .hot-key-tip {
      font-size: 12px;
      color: #F56C6C;
      margin-top: 20px;
    }
	}
</style>
