<template>
	<div class="live-shorthand-page full-width">

		<div class="player-area">
			<div class="player-tip">
				<template>
					<span class="player-tip-content" v-if="startTime > 0">
						离直播开始：{{global.utils.TimeFormat(Math.abs(startTime))}}
					</span>

					<span class="player-tip-content" v-else>
						直播流时间 ： {{global.utils.TimeFormat(liveTime)}}
					</span>
				</template>
				
				<span v-if="startTime < 0" class="player-tip-content">提交任务倒计时 ： {{global.utils.TimeFormat(Math.abs(remainTime) , 1)}}</span>

				<span v-else class="player-tip-content">
					任务暂未开始
				</span>
			</div>

			<div class="player">
				<live-player :url="playUrl" @playerInited="playerInited"></live-player>
			</div>
		</div>
		

		<div class="text-area">
			<el-tabs type="card" value="first">
				<el-tab-pane :label="`原始语言：${originalLanguageName}`" name="first" class="ciku">
					<el-input @blur="inputBlur" ref="input" v-model="input" class="textarea" type="textarea" :autosize="{ minRows: 17, maxRows: 17}"></el-input>
				</el-tab-pane>
			</el-tabs>

			<div class="button">
				<el-button type="text" size="small" @click="refreshLineWord">手动刷新</el-button>
			</div>
		</div>

		<div class="line-book">
			<line-book-title :taskId="taskId" ref="linebook" @copyClick="LineBookCopyClick"></line-book-title>
		</div>
	</div>

	
</template>

<script type="text/javascript">
	import LivePlayer from "@/components/common/LivePlayer/LivePlayer.vue"
	import LineBookTitle from "./sub/LineBookTitle.vue"
	export default {
		name : "live-shorthand-page",
		components : {
			LivePlayer,
			LineBookTitle
		},
		data (){
			return {
				input : "",
				playUrl : "",
				taskId : this.$route.query.taskId,
				startTime : -1,
				remainTime : 120,
				intervalTime : 0,
				latestTime : 0,
				intervalId : -1,
				taskInfo : {},
				originalLanguageName : "",
				videoPlayer : null,
				liveTime : "播放暂未开始",
				clickedLineBook : [],
				inputSelectionTarget : -1
			}
		},
		methods : {
			//播放器初始化事件回调
			playerInited(player){
				this.videoPlayer = player
			},
			getTaskInfo(){
				if(!this.taskId){
					this.$message.warning("任务无效");
					return false;
				}
				let url = `/api-intl-translate-live/translate-live-task/query-synchronize-task-info?taskId=${this.taskId}&roleId=8`;
				$$.get(url).then(data => {
					this.playUrl = data.liveStreamUrl;
					this.startTime = data.liveStartCountDown;
					this.remainTime = data.taskRemainTime || data.intervalTime;
					this.intervalTime = data.intervalTime;
					this.originalLanguageName = utils.format.language(null , null , data.originalLanguage);
					this.start();
				}).catch(err => {
					this.$message.error(err.message)
				})

				//拉取任务数据的时候同时更新台本信息
				this.refreshLineWord();
			},
			start(){
				if(this.intervalId > 0)this.stop();
				this.intervalId = setInterval(() => {
					this.startTime -= 200;

					if(this.startTime > 0){
						return false;
					}else if(this.startTime > -200){
						setTimeout(() => {
							this.start();
						} , Math.abs(this.startTime))
						return false;
					}

					this.remainTime -= 200;
					
					if(this.remainTime < 0){
						setTimeout(() => {
							this.send();
						} , Math.abs(this.remainTime))

						this.remainTime += this.intervalTime
					}
				} , 200)
			},
			stop(){
				clearInterval(this.intervalId);
				this.intervalId = -1;
			},
			send(){
				let url = `/api-intl-translate-live/translate-live-fragment-short-hand/push-short-hand-result`;
				let input = this.input ? this.input : "";
				let contents = input.split("。");
				//删除最后一个数据，留在当前输入框
				this.input = contents.pop();
				$$.post(url , {
					shortHandContents : contents.map((v , k) => this.lineContentParse(k , v , contents)),
					taskId : this.taskId
				}).then(()=>{
					//提交完成后再次校准时间
					this.getTaskInfo();
					//清除已点击过的台本数据，防止内存泄露
					this.clickedLineBook = [];
				})
			},
			//每一条字幕通过文字内容转换成对应的对象
			lineContentParse(index , line , contents){
				let content = "",
					id = "";

				if(/^\{(.*)?\}$/.test(line)){
					content =  line.substring(1 , line.length - 1);
					id = this.getLineBookNumber(content);

					//如果存在ID，则把ID前缀也去除
					if(id)content = content.substring(content.indexOf(".") + 1);
				}else{
					content =  line;
				}

				return {
					content : content,
					scriptDetailId : id ? id : null,
					isScript : !!id
				}
			},
			//判断语句是否是台本里内容
			getLineBookNumber(content){
				let contents = content.split(".");

				//有序号且序号可以查询到的，并且内容没有被更改的，返回对应的序号ID
				if(contents.length >= 2){
					let id = contents[0];
					let line = content = content.substring(content.indexOf(".") + 1);
					let linebook = this.clickedLineBook.filter(v => v.id == id);
					if(linebook.length > 0 && linebook[0].content == content){
						return id;
					}
				}

				return false;
			},
			updateLiveTime(){
				this.liveTime = this.videoPlayer.currentTime() * 1000;
			},
			windowVisibleEventBind(){
				document.addEventListener("visibilitychange" , this.windowVisibleChange)
			},
			windowVisibleEventUnbind(){
				document.removeEventListener("visibilitychange" , this.windowVisibleChange)
			},
			windowVisibleChange(){
				if(!document.hidden){
					this.getTaskInfo();
				}
			},
			//刷新台词本数据
			refreshLineWord(){
				this.$refs.linebook.getDetail();
			},
			inputBlur(e){
				let target = e.target;
				this.inputSelectionTarget = target.selectionEnd;
			},
			LineBookCopyClick(linebook){
				let lines = "。" + linebook.originalLanguageContents.map(v => `{${v.id}.${v.content}}`).join("。") + "。"
				if(this.inputSelectionTarget >= 0){
					this.input = this.input.slice(0 , this.inputSelectionTarget) + lines + this.input.slice(this.inputSelectionTarget);
				}else{
					this.input += lines;
				}

				this.clickedLineBook = this.clickedLineBook.concat(linebook.originalLanguageContents);
			}
		},
		mounted(){
			this.getTaskInfo();
			this.$refs.input.focus();
			this.windowVisibleEventBind();
		},
		watch : {
			videoPlayer(n , o){
				if(!o){
					this.videoPlayer.on("timeupdate" , this.updateLiveTime);
				}
			}
		},
		beforeDestroy(){
			if(this.videoPlayer){
				this.videoPlayer.off("timeupdate" , this.updateLiveTime);
			}
			this.stop();
			this.windowVisibleEventUnbind();
		}
	};
</script>

<style lang="scss">
	.live-shorthand-page{
		display : flex;
		.player-area{
			width : 600px;
			.player-tip{
				.player-tip-content{
					line-height : 60px;
					height : 60px;
					display: inline-block;
					width : 50%;
					text-align : center;
					border : 1px solid #666;
					margin-bottom : 5px;
				}
			}		
		}

		.text-area{
			margin-left : 15px;
			flex : 1 1 auto;
			.textarea {
				margin : 0 15px 0 0;
			}
			position : relative;
			.button{
				position : absolute;
				top : 0;
				right : 0;
				height : 40px;
				line-height : 40px;
			}
		}	

		.el-tabs__header{
			margin-bottom : 0;
		}

		.line-book{
			width : 200px;
			margin-left : 15px;
			height : 90vh;
			overflow : auto;
		}
	}
</style>