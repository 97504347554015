<template>
  <el-dialog title="导入术语表" :visible="visible" @open="getTermFile" @close="cancel">
    <el-alert
      title="①表格首行语种必须按模板文版填写 ②语种顺序不限 ③不可有重复语种列"
      type="warning"
      :closable="false">
    </el-alert>

    <el-row type="flex" :style="{marginTop: '20px'}">
      <div class="label">术语表</div>
      <file-upload accept=".xls,.xlsx" v-model="file"></file-upload>
    </el-row>

    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="submit" type="primary">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FileUpload from './Upload'
import {importTermFile, getTermFile} from '../service'

export default {
  name: 'TermImportModal',

  props: {
    id: {
      type: [Number, String],
      default: ''
    },
    visible: {
      type: Boolean,
      defatult: false
    }
  },

  data() {
    return {
      file: {},
      cacheFile: {}
    }
  },

  methods: {
    async submit() {
      const {id, file, cacheFile} = this;
      if ((file.url && file.url === cacheFile.url) || (!file.url && !cacheFile.url)) {
        this.$message.warning('文件未变更')
        return
      }
      try {
        const params = {
          files: [{
            name: file.name,
            scriptUrl: file.url
          }],
          taskId: id
        }
        await importTermFile(params)
        this.$message.success('导入成功')
        this.cancel()
        this.$emit('success')
      } catch ({message}) {
        this.$message.error(message)
      }
    },

    async getTermFile() {
      const {id} = this;
      const file = await getTermFile(id);
      this.file = file.map(({id, name, scriptUrl}) => ({id, name, url: scriptUrl}))[0] || {}
      console.log(this.file)
      this.cacheFile = _.cloneDeep(this.file);
    },

    cancel() {
      this.$emit('update:visible', false)
      this.file = {}
      this.cacheFile = {}
    }
  },

  components: {
    FileUpload
  }
}
</script>

<style scoped>
  .label {
    font-size: 14px;
    line-height: 28px;
    color: #606266;
    margin-right: 20px;
  }
</style>