<template>
	<div style="display : flex">
			<el-upload
				v-model="img"
				with-credentials
				:action="action"
				:limit="2"
				:file-list="fileList"
				:data="{id : id}"
				:on-success="getSubtitle"
				:on-error="fileError"
				ref="subtitleUpload"
				:accept="accept"
				:before-upload="beforeAvatarUpload"
				:on-remove="removeUpload"
				:show-file-list="false"
				:disabled="disabled"
				:headers="{Opsession : global.utils.getCookie('OPSESSION')}"
				>
					<el-button size="small" type="primary" v-if="!disabled">点击上传</el-button>
				</el-upload>
			<div style="height : 30px;">
				<el-tag style="margin-left : 15px;"
				:closable="!disabled" 
				v-for="(item , index) in fileList"
				:key="index"
				@close="remove(index)">
					<span :title="item.name" class="file-tags" style="">{{item.name}}</span>
				</el-tag>
			</div>
			
		</div>
	
</template>

<script type="text/javascript">
	export default {
		name : "line-upload",
		props : {
			id : {
				type : [Number , String],
				default : 0
			},
			value : {
				type : Object,
				default : ()=>{
					return {}
				}
			},
			accept : {
				type : String,
				default : ""
			},
			disabled : {
				type : Boolean,
				default : false
			}
		},
		data (){
			return {
				img : this.value.url,
				picName : this.value.name,
				action : `${this.http_pre}/api-intl-translate/file/upload`
			}
		},
    computed: {
      fileList: {
        get() {
          const {url, name} = this.value;
          let file_name;
          let file_ext;
          if (url) {
            file_name = url.replace(/(.*\/)*([^.]+).*/ig,"$2");
            file_ext = url.replace(/.+\./,"");
          }
          return url ? [{
            name: name ? name : `${file_name}.${file_ext}`,
            url
          }] : []
        },
        set(value = []) {
          return value
        }
      }
    },
		watch : {
			img (){
				this.$emit("input" , {
					url : this.img,
					name : this.picName
				});
			}
		},
		methods : {
			beforeAvatarUpload (file){
				if(file.size >= 10485760){
					this.$message.warning("上传文件超过大小限制，最大10M");
					return false;
				}

				return true;
			},
			fileError(err, file, fileList){
				var message = JSON.parse(err.message)
				this.$message.warning(message.message)
			},
			getSubtitle(response, file, fileList){
				console.log(file);
				if(fileList.length > 1){
					fileList.splice(0 , 1);
				}
				this.fileList = fileList;
				this.img = file.response.data;
				this.picName = file.name;
			},
			removeUpload(file , fileList){
				this.fileList = fileList;
				this.img = "";
				this.picName = "";
			},
			remove(index){
				this.fileList.splice(index , 1);
				this.img = "";
				this.picName = "";
			}
		}
	};
</script>

<style lang="scss" scoped="">
	.file-tags{
		display : inline-block;
		max-width : 150px;
		overflow : hidden;
		vertical-align:middle;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>